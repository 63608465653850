<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="选择职位"></nav-bar>
    </template>
    <van-cell-group>
      <van-cell v-for="item in positionItem"
                :title="item.displayName"
                :key="item.id"
                is-link
                clickable
                @click="linkToSelect(item)"
      />
    </van-cell-group>
    <div class="add-btn" @click="linkToAdd">
      <img src="../../../assets/images/icon/add.png" alt="">
    </div>
    <template v-slot:routerView>
      <router-view></router-view>
    </template>
  </page-view>
</template>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import { Cell, CellGroup } from 'vant'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {}
  },
  components: {
    PageView,
    NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  created () {
  },
  mounted () {
    this.loadPositionItem()
  },
  beforeDestroy () {
  },
  methods: {
    loadPositionItem () {
      this.$store.dispatch('permission/loadSelectList')
    },
    linkToSelect (positionObj) {
      this.$store.dispatch('permission/setPositionObj', { id: positionObj.id, displayName: positionObj.displayName })
      this.$router.back()
    },
    linkToAdd () {
      this.$router.push(`${this.$route.path}/add-position`)
    }
  },
  computed: {
    ...mapGetters({
      positionItem: 'permission/getSelectList'
    })
  },
  watch: {}
}
</script>
